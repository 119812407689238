<script setup>
import WarningDialog from "@/components/common/WarningDialog.vue";
import useDamageReports from "@/hooks/useDamageReports";
import { useUserStore } from "@/stores/user";
import { ref } from "vue";
import { useRouter } from "vue-router";

const { approveDamageReport, rejectDamageReport, startDamageReview, startProcessingDR, processDamageReport, workflowStateColor, damageLoading } = useDamageReports();

const userStore = useUserStore();
const router = useRouter();
const dialog = ref(false);
const state = ref("");
const note = ref(null);
const warningMessage = {
  message: "",
  color: "red",
};

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  damage: {
    type: Object,
    required: true,
  },
});

const navItems = (id) => {
  return [
    {
      text: "Description",
      icon: "mdi-file-outline",
      to: `/damage-reports/${id}`,
    },
    {
      text: "Photos",
      icon: "mdi-camera",
      to: `/damage-reports/${id}/photos`,
    },
    {
      text: "Transactions",
      icon: "mdi-receipt",
      to: `/damage-reports/${id}/transactions`,
    },
  ];
};

const startProcessing = async () => {
  const res = await startProcessingDR(props.damage?.id);
  if (res) {
    router.go();
  }
}

const transitionState = (param) => {
  state.value = param;
  dialog.value = true;
  if (state.value == "processing") {
    warningMessage.message =
      "Are you sure you want to process this damage report?";
  } else if (state.value == "approve") {
    warningMessage.message =
      "Approving a damage report will move inventory from it's original pallet to a new pallet in the noted bin.";
  } else if (state.value == "reject") {
    warningMessage.message =
      "Are you sure you want to cancel this damage report? This action cannot be undone.";
  }
};

const handleDialogActions = async (action) => {
  if (state.value == "processing" && action == true) {
    const res = await processDamageReport(props.damage?.id, note.value);
    dialog.value = false;
    if (res) {
      router.go();
    }
  } else if (state.value == "approve" && action == true) {
    let reviewed = null
    if (props.damage?.workflow_state == "in_review") {
      reviewed = await startDamageReview(props.damage?.id);
    }
    if (reviewed || props.damage?.workflow_state == "reviewing") {
      const res = await approveDamageReport(props.damage?.id, note.value);
      dialog.value = false;
      if (res) {
        router.go();
      }
    }
  } else if (state.value == "reject" && action == true) {
    let reviewed = null
    if (props.damage?.workflow_state == "in_review") {
      reviewed = await startDamageReview(props.damage?.id);
    }
    if (reviewed || props.damage?.workflow_state == "reviewing") {
      const res = await rejectDamageReport(props.damage?.id, note.value);
      dialog.value = false;
      if (res) {
        router.go();
      }
    }
  } else {
    dialog.value = false;
  }
};
</script>

<template>
  <v-card flat class="d-flex flex-wrap mx-4 border">
    <div class="pl-4 py-4 w-100 bg-surface border-b d-flex align-center">
      <a class="text-h7" href="/damage-reports">Damage Reports</a>
      <span class="px-2">//</span>
      <span class="text-h6">DMG-RPT-{{ damage?.id }} {{ title }} </span>
    </div>
    <div class="w-100 d-flex">
      <div class="w-25 overflow-y-auto border-e-sm">
        <div class="w-80 ma-4">
          <WarningDialog
            v-if="dialog"
            :callback="handleDialogActions"
            :msg="warningMessage"
            title="WARNING"
          >
            <div class="">
              <v-textarea label="Notes (required)" aria-required="true" v-model="note" />
            </div>
          </WarningDialog>
          <v-overlay
            persistent
            :model-value="damageLoading"
            class="align-center justify-center"
          >
            <v-progress-circular
              color="primary"
              size="64"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
          <!-- MIGHT BE NEEDED IN FUTURE -->
          <!-- <v-btn
            v-if="damage?.workflow_state == 'new'"
            block
            variant="outlined"
            :color="workflowStateColor(damage?.workflow_state)"
            class="mb-2"
            @click="startProcessing"
            :disabled="!userStore.user?.permissions?.process_damage_reports"
          >
            Start Processing
          </v-btn>          
          <v-btn
            v-else-if="damage?.workflow_state == 'processing'"
            block
            variant="outlined"
            :color="workflowStateColor(damage?.workflow_state)"
            @click="transitionState('processing')"
            :disabled="!userStore.user?.permissions?.process_damage_reports"
          >
            Process
          </v-btn>
          <v-btn v-else-if="damage?.workflow_state == 'in_review' || damage?.workflow_state == 'reviewing'"
            block
            variant="outlined"
            color="success"
            class="mb-2"
            @click="transitionState('approve')"
            :disabled="!userStore.user?.permissions?.review_damage_reports"
          >
            Approve
          </v-btn> -->
          <v-btn 
            block 
            variant="outlined" 
            :color="workflowStateColor(damage?.workflow_state)" 
            disabled
            >
              {{ damage?.workflow_state }}
          </v-btn>
        </div>
        <v-divider></v-divider>

        <v-card flat class="mx-auto pa-2 w-100">
          <div class="text-center" v-if="!damage">
            <v-progress-circular
              :width="8"
              :size="50"
              color="primary"
              class="mb-16"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list v-if="props.damage">
            <v-list-item
              exact
              v-if="damage"
              v-for="(item, i) in navItems(damage.id)"
              :key="i"
              :value="item"
              :to="item.to"
              color="primary"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>

              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
        <v-divider></v-divider>
      </div>
      <div class="w-75 overflow-y-auto">
        <slot />
      </div>
    </div>
  </v-card>
</template>

<style scoped>
@media (max-width: 768px) {
  .v-card .mx-auto {
    max-width: 75px;
  }
}
</style>
